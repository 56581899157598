// material-ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// project import

// assets
import { Button, Typography, useTheme } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';
import DashboardHeader from '../header/header';
import useAuth from '@/hooks/useAuth';
import { config } from '@/contexts/config';
import Shift4CheckoutForm from '../subscription/form/shift4-checkout-form';
import OneTimePaymentInfoPanel from '../subscription/OneTimePaymentInfoPanel';
import SubscriptionCard from '../subscription/SubscriptionCard';
// ==============================|| DASHBOARD - WRAPPER ||============================== //

export default function CheckoutWrapper() {
    const theme = useTheme();
    const { user } = useAuth();
    const navigate = useNavigate();
    const credits = user?.credits_count != null && user?.credits_count != 0 ? user?.credits_count : user?.demo_credits_count != null ? user?.demo_credits_count : 0;

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            {/* Header Section */}
            <DashboardHeader credits={credits} />
            {/* Content Section */}
            <Grid container sx={{ flexGrow: 1 }}>
                <Grid item xs={12} md={8} sx={{ backgroundColor: 'primary.main', paddingBottom: '50px' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flex: '1 1 auto',
                            }}
                        >
                            <SubscriptionCard>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <RouterLink to="/dashboard">
                                            <Button startIcon={<ArrowLeftOutlined />} sx={{ textTransform: 'none', color: theme.palette.primary[200], paddingLeft: 0 }}>Back to dashboard</Button>
                                        </RouterLink>
                                        <Typography variant='h2'>Buy {config.credits_count} credits</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: '30px' }}>
                                        <Shift4CheckoutForm />
                                    </Grid>
                                </Grid>
                            </SubscriptionCard>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} sx={{ backgroundColor: 'primary.darker' }}>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            justifyContent: 'start',
                            alignItems: 'flex-start',
                            height: '100%',
                        }}
                    >
                        <OneTimePaymentInfoPanel />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
