import useAuth from "@/hooks/useAuth";
import { useIsActiveRoute } from "@/utils/is-active-route";
import { DollarOutlined, PlusCircleOutlined, HomeOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Stack, useTheme } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";

export default function AccountMenu() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { logout, user } = useAuth();

    const [openAccountMenu, setOpenAccountMenu] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpenAccountMenu((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpenAccountMenu(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenAccountMenu(false);
        } else if (event.key === 'Escape') {
            setOpenAccountMenu(false);
        }
    }

    const prevOpen = React.useRef(openAccountMenu);
    React.useEffect(() => {
        if (prevOpen.current === true && openAccountMenu === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = openAccountMenu;
    }, [openAccountMenu]);

    const handleLogout = async () => {
      try {
        await logout();
        navigate(`/login`, {
          state: {
            from: ''
          }
        });
      } catch (err) {
        console.error(err);
      }
    };

    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleToggle}
                variant="text"
                startIcon={<UserOutlined style={{ color: theme.palette.primary.lighter }} />}
                sx={{ display: 'flex', textTransform: 'none', color: theme.palette.primary.lighter, justifyContent: { xs: 'end', sm: 'center' }, minWidth: { xs: '34px' } }}
            >
                <Box sx={{ marginLeft: '5px', display: { xs: 'none', sm: 'flex' } }}><span style={{ fontWeight: 'normal' }}>Account</span></Box>
            </Button>
            <Stack direction="row" spacing={2} sx={{ zIndex: 9999, justifyContent: 'center', alignContent: 'center' }}>
                <div>
                    <Popper
                        open={openAccountMenu}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-start' ? 'right top' : 'right bottom',
                                }}
                            >
                                <Paper sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white }}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={openAccountMenu}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            <MenuItem sx={{ display: { xs: 'none', sm: 'flex' }, color: useIsActiveRoute("/dashboard") ? theme.palette.secondary.light : theme.palette.common.white, alignContent: 'center', paddingLeft: '8px', "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.common.white } }} onClick={() => navigate("/dashboard")}><HomeOutlined style={{ marginRight: "14px" }} /> Dashboard</MenuItem>
                                            {/* <MenuItem sx={{ display: { xs: 'none', sm: 'flex' }, color: useIsActiveRoute("/custom-model") ? theme.palette.secondary.light : theme.palette.common.white, alignContent: 'center', paddingLeft: '8px', "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.common.white } }} onClick={() => navigate("/custom-model")}><PlusCircleOutlined style={{ marginRight: "14px" }} /> Create model</MenuItem> */}
                                            <MenuItem sx={{ display: { xs: 'none', sm: 'flex' }, color: useIsActiveRoute("/profile") ? theme.palette.secondary.light : theme.palette.common.white, alignContent: 'center', paddingLeft: '8px', "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.common.white } }} onClick={() => navigate("/profile")}><UserOutlined style={{ marginRight: "14px" }} /> My profile</MenuItem>
                                            <MenuItem sx={{ display: { xs: 'none', sm: 'flex' }, color: useIsActiveRoute("/billing") ? theme.palette.secondary.light : theme.palette.common.white, alignContent: 'center', paddingLeft: '8px', "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.common.white } }} onClick={() => navigate("/billing")}><DollarOutlined style={{ marginRight: "14px" }} /> Billing</MenuItem>
                                            <MenuItem sx={{ alignContent: 'center', paddingLeft: '8px', "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.common.white } }} onClick={handleLogout}><LogoutOutlined  style={{ marginRight: "14px" }} /> Logout</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </Stack>
        </>
    );
}