import axiosServices from "@/utils/axios";
import { TRANSACTIONS_API_URL } from "./utils";
import { AxiosError } from "axios";

type TransactionSuccessResponse = {
    id: number;
    state: string;
    three_d_redirect_url: string;
    amount: {
        cents: number;
        currency_iso: string;
    };
};

type TransactionErrorResponse = {
    errorMessage: string;
};

type ResponseWithErrors = {
    errors: Array<any>;
};

type TransactionApiResponse = TransactionSuccessResponse | TransactionErrorResponse;


export const submitTransaction = async (paymentMethodToken: string, country: string, provider: string = "centrobill"): Promise<TransactionApiResponse> => {
    try {
        const payload = {
            transaction: {
                payment_method_token: paymentMethodToken,
                country: country,
                provider: provider
            }
        };
        
        const res = await axiosServices.post(`${TRANSACTIONS_API_URL}`, payload);

        if (res.data.state == "succeeded" || res.data.state == "pending"){
            return {
                id: res.data.id,
                state: res.data.state,
                three_d_redirect_url: res.data.three_d_redirect_url,
                amount: {
                    cents: res.data.amount.cents,
                    currency_iso: res.data.amount.currency_iso,
                },
            } as TransactionSuccessResponse;
        }

        return {
            errorMessage: "Something went wrong. Please try again."
        } as TransactionErrorResponse;
        
    } catch (error) {
        return {
            errorMessage: "Payment was not successful. Please try again or contact support."
        } as TransactionErrorResponse;
    }
};
