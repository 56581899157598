import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useTheme, Box, DialogProps } from '@mui/material';
import { boolean } from 'yup';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
    actions?: React.ReactNode;
    bgColor?: string;
    headerBorder?: boolean;
    addBorderOnContent?: boolean;
}

export default function BaseModal({
    open,
    onClose,
    title,
    children,
    actions,
    bgColor,
    addBorderOnContent,
    headerBorder = true
}: ModalProps) {
    const theme = useTheme();
    useEffect(() => {
        if (open) {
          document.body.style.backgroundColor = theme.palette.primary.darker;
        }
      }, [open]);
    
    return (
        <Dialog
        sx={{
            zIndex: (theme) => theme.zIndex.modal + 1,
        }}
            open={open}
            onClose={onClose}
            maxWidth={'xs'}
            fullWidth={true}
            PaperProps={{
                sx: {
                    borderRadius: '8px',
                    backgroundColor: bgColor ?? theme.palette.primary.darker,
                    backgroundImage: 'none',
                    //minWidth: '400px',
                    paddingBottom: '30px',
                },
            }}
        >
            <DialogTitle sx={{ fontWeight: 600, fontSize: '20px', borderBottom: headerBorder ? `1px solid ${theme.palette.primary.light}` : 'none', pl: '40px' }}>
                {title}
            </DialogTitle>
            {addBorderOnContent ? (
                <Box
                    sx={{
                        border: `1px solid ${theme.palette.secondary.light}`,
                        borderRadius: '8px',
                        margin: '20px',
                        ml: '40px',
                        mr: '40px'
                    }}
                >
                    <DialogContent sx={{ pt: '24px', pb: '12px', mt: '10px', pl: '30px', pr: '30px' }}>
                        {children}
                    </DialogContent>
                    {actions && <DialogActions sx={{ justifyContent: 'center', pt: '0px' }}>{actions}</DialogActions>}
                </Box>
            ) : (
                <>
                    <DialogContent sx={{ p: '12px', mt: '10px', pl: '30px', pr: '30px' }}>
                    {children}
                    </DialogContent>
                    {actions && <DialogActions sx={{ justifyContent: 'center' }}>{actions}</DialogActions>}
                </>
            )}

        </Dialog>
    );
};
