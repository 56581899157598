import { LockFilled } from "@ant-design/icons";
import { Box, Button, Card, CardContent, CardHeader, FormHelperText, Grid, Link, OutlinedInput, Popper, Stack, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CardLogos from 'assets/images/Card Logos.png';

import { AxiosError } from 'axios';
import { submitTransaction } from '@/api/transactions';
import { useEffect, useMemo, useRef, useState } from "react";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Loader from "@/components/Loader";
import countryList from 'react-select-country-list'
import { Autocomplete, TextField } from '@mui/material';
import * as Yup from 'yup';
import useAuth from "@/hooks/useAuth";
import { config } from '@/contexts/config';

declare global {
    interface Window {
        Shift4: any; // Replace `any` with a proper type if available
    }
}

export default function Shift4CheckoutForm() {
    const theme = useTheme();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [currentValues, setCurrentValues] = useState({selectedCountry: '', cardHolder: ''});
    const options = useMemo(() => countryList().getData(), []);
    const { fetchUser } = useAuth();

    const shift4 = useRef<any>(null); // Replace `any` with the appropriate type if you define one.
    const shift4Components = useRef<any>(null);
    
    useEffect(() => {
      if (!window.Shift4) {
        console.error('Shift4.js library is not loaded');
        return;
      }

      // Initialize Shift4.js with your public key
      shift4.current = window.Shift4('pk_test_upe6bEziB2WFjITEWPKccwx9');
      shift4Components.current = shift4.current.createComponentGroup().automount("#shift4-form");
    }, []);

    const tokenCreatedCallback = (token: any) => {
        var request = {
            amount: config.price * 100,
            currency: 'USD',
            card: token.id
        };

        // Open frame with 3-D Secure process
        shift4.current.verifyThreeDSecure(request).then(threeDSecureCompletedCallback).catch(errorCallback);
    }

    const errorCallback = (error: any) => {
        setLoading(false);

        if (error instanceof AxiosError) {
            const response = error.response;
            if (response?.data.errors) {
                setErrorMessage(Object.values(response?.data.errors).join(", "));
            } else {
                setErrorMessage("Something went wrong. Please try again.");
            }
        } else if ('message' in error) {
            setErrorMessage(error.message);
        } else {
            setErrorMessage("Something went wrong. Please try again.");
        }
    }

    const threeDSecureCompletedCallback = async (token: any) => {
        setLoading(true);

        const transactionResponse = await submitTransaction(
            token.id, currentValues.selectedCountry, "shift4"
        );

        if ('errorMessage' in transactionResponse) {
            setErrorMessage(transactionResponse.errorMessage);
        }
        else {
            fetchUser();
            const queryParams = new URLSearchParams({ success: 'true' });

            navigate(`/dashboard?${queryParams.toString()}`);
        }

        setLoading(false);
    }

    const checkout = async () => {
        setLoading(true);

        shift4.current.createToken(shift4Components.current, {cardholderName: currentValues.cardHolder, addressCountry: currentValues.selectedCountry})
            .then(tokenCreatedCallback)
            .catch(errorCallback);
    };

    const validationSchema = Yup.object({
        selectedCountry: Yup.string().required('Country is required'),
        cardHolder: Yup.string().required('Cardholder name is required')
    });
    return (
        <>
            {loading && <Loader />}
            {errorMessage && (
                <Box
                    className="error-message"
                    sx={{
                        color: '#EC928E',
                        border: '1px solid #EC928E',
                        backgroundColor: '#E4696214',
                        padding: '10px',
                        borderRadius: '0px',
                        margin: '10px 0',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <ExclamationCircleOutlined style={{ color: '#EC928E', marginRight: '8px' }} />
                    {errorMessage}
                </Box>
            )}
            <Formik
                initialValues={{
                    cardHolder: '',
                    selectedCountry: '',
                    submit: null
                }}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    console.log(values);
                    setCurrentValues({selectedCountry: values.selectedCountry, cardHolder: values.cardHolder});

                    await checkout();
                }}

                validationSchema={validationSchema}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form id="shift4-form" onSubmit={handleSubmit}>
                        <Stack>
                            <Box sx={{ marginTop: '10px' }}>
                                <Typography variant='h3' sx={{ fontWeight: 600, mb: 3 }}>
                                    Payment details
                                </Typography>
                                <Stack spacing={3}>
                                    <Stack spacing={1} sx={{ maxWidth: '300px' }}>
                                        <label style={{ color: theme.palette.text.primary, fontWeight: 500 }}>Cardholder name</label>
                                        <OutlinedInput
                                            id="cardHolder"
                                            type="text"
                                            name="cardHolder"
                                            value={values.cardHolder}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            error={Boolean(touched.cardHolder && errors.cardHolder)}
                                            placeholder="John Doe"
                                            inputProps={{ maxLength: 64 }}
                                            sx={{ '& input': { background: 'white', color: "black" } }}
                                        />
                                    </Stack>
                                    <Stack spacing={1} sx={{ maxWidth: '300px' }}>
                                        <label style={{ color: theme.palette.text.primary, fontWeight: 500 }}>Card Number</label>
                                        <Box
                                            data-shift4="number"
                                            sx={{
                                                border: '1px solid #ced4da',
                                                padding: '10px',
                                                fontSize: '16px',
                                                boxSizing: 'border-box',
                                                background: 'white'
                                            }}
                                        />
                                    </Stack>

                                    <Grid container sx={{ gap: '3%' }}>
                                        <Grid item xs={3}>
                                            <Stack spacing={1}>
                                                <label style={{ color: theme.palette.text.primary, fontWeight: 500 }}>Expiry Date</label>
                                                <Box
                                                    data-shift4="expiry"
                                                    sx={{
                                                        border: '1px solid #ced4da',
                                                        padding: '10px',
                                                        fontSize: '16px',
                                                        boxSizing: 'border-box',
                                                        background: 'white'
                                                    }}
                                                />

                                                <label style={{ color: '#8f8f8f', fontWeight: 100, fontSize: '10px' }}>MM/YY</label>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Stack spacing={1}>
                                                <label style={{ color: theme.palette.text.primary, fontWeight: 500 }}>CVC</label>
                                                <Box
                                                    data-shift4="cvc"
                                                    sx={{
                                                        border: '1px solid #ced4da',
                                                        padding: '10px',
                                                        fontSize: '16px',
                                                        boxSizing: 'border-box',
                                                        background: 'white'
                                                    }}
                                                />
                                            
                                                <label style={{ color: theme.palette.primary[200], fontWeight: 100, fontSize: '10px' }}>3 digits</label>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    <Stack spacing={1} sx={{ maxWidth: '300px' }}>
                                        <label style={{ color: theme.palette.text.primary, fontWeight: 500 }}>Country</label>
                                        <Autocomplete
                                            id="country-select"
                                            options={options}
                                            getOptionLabel={(option) => option.label}
                                            value={options.find((country: any) => country.value === values.selectedCountry) || null}
                                            onChange={ (event, newValue) => { 
                                                values.selectedCountry = newValue ? newValue.value : ''
                                                setCurrentValues({selectedCountry: values.selectedCountry, cardHolder: values.cardHolder})
                                            } }
                                            renderInput={(params) => (
                                                <TextField sx={{ color: "black" }} {...params} variant="outlined" />
                                            )}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            sx={{
                                                backgroundColor: 'white',
                                                color: 'black',
                                                '& .MuiOutlinedInput-root': {
                                                    height: '45px',
                                                    color: 'black'

                                                }
                                            }}
                                            ListboxProps={{
                                                sx: {
                                                    backgroundColor: "white",
                                                    color: "black"
                                                },
                                            }}
                                        />
                                        <label
                                            style={{
                                                color: theme.palette.primary[200],
                                                fontWeight: 100,
                                                fontSize: '10px',
                                                marginTop: '8px',
                                            }}
                                        >
                                            Please select a country
                                        </label>
                                    </Stack>
                                </Stack>
                                <Box sx={{ marginTop: 4 }}>
                                    <Typography variant='body1' sx={{ color: theme.palette.primary[200] }}>
                                        <LockFilled /> This is a secure payment and encrypted payment
                                    </Typography>
                                </Box>
                                <Grid container spacing={2} sx={{ marginTop: '20px', alignItems: 'center' }}>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            startIcon={<LockFilled />}
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            sx={{
                                                fontWeight: 700,
                                                color: theme.palette.common.white,
                                            }}
                                        >
                                            Pay Now
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} sm={4} textAlign="start">
                                        <Typography
                                            variant="body1"
                                            sx={{ color: theme.palette.primary[200], fontSize: '12px' }}
                                        >
                                            I agree to Undressly's{" "}
                                            <Link
                                                underline="none"
                                                variant="body1"
                                                component={RouterLink}
                                                target="_blank"
                                                to="https://www.undressly.ai/terms-and-conditions"
                                                sx={{
                                                    fontWeight: 600,
                                                    color: theme.palette.secondary.main,
                                                    fontSize: '12px',
                                                }}
                                            >
                                                Terms and Conditions
                                            </Link>,
                                            <Link
                                                underline="none"
                                                variant="body1"
                                                component={RouterLink}
                                                target="_blank"
                                                to="https://www.undressly.ai/refund-policy"
                                                sx={{
                                                    fontWeight: 600,
                                                    color: theme.palette.secondary.main,
                                                    fontSize: '12px',
                                                }}
                                            >
                                                Refund Policy
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Box sx={{ mt: '20px' }}>
                                    <img
                                        src={CardLogos}
                                        alt="Card Logos"
                                        style={{
                                            height: '50px',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </Box>

                            </Box>
                        </Stack>
                    </form>

                    
                )}
            </Formik>
        </>

    );
}