import { Box, Button, Divider, Drawer, IconButton, Toolbar, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { HomeFilled, DollarOutlined, UserOutlined, HomeOutlined, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Logo from 'assets/images/Logo.svg';
import { useIsActiveRoute } from "@/utils/is-active-route";
import DiscordGrey from 'assets/images/discord-grey.svg';

export type HeaderDrewerProps = {
    drawerOpen: boolean,
    toggleDrawer: (open: boolean) => () => void;
}

export default function HeaderDrewer({ drawerOpen, toggleDrawer }: HeaderDrewerProps) {
    const theme = useTheme();
    const navigateToDiscord = () => {
        toggleDrawer(false);
        window.open("https://discord.gg/SeRW3Y3ura", "_blank");
    }

    return (
        <>
            <Drawer
                sx={{
                    width: 240,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: 240,
                        backgroundColor: theme.palette.primary.darker,
                        borderRight: 'none'
                    },
                }}
                variant="persistent"
                anchor="left"
                open={drawerOpen}
            >
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: theme.spacing(0, 1),
                    justifyContent: "flex-start",
                    paddingY: '0px',
                    backgroundColor: theme.palette.primary.darker,
                    minHeight: '66px'
                }}>
                    <IconButton onClick={toggleDrawer(false)}>
                        <CloseOutlined />
                    </IconButton>
                    <Link to="/">
                        <Box
                            component="img"
                            src={Logo}
                            alt="Logo"
                            sx={{
                                height: '19px',
                                marginLeft: '8px',
                                marginTop: { xs: '5px', sm: '0px' },
                                width: 'auto',
                            }}
                        />
                    </Link>
                </Box>
                <Divider />
                <Box sx={{
                    padding: '0 16px', marginTop: '20px', width: 240,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <Button startIcon={<HomeOutlined />} onClick={toggleDrawer(false)} sx={{ color: useIsActiveRoute("/dashboard") ? theme.palette.secondary.light : theme.palette.primary[100], fontWeight: 100, "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.primary[100] } }}><span style={{ marginTop: '2px' }}>Dashboard</span></Button>
                    </Link>
                    {/* <Link to="/custom-model" style={{ textDecoration: 'none' }}>
                        <Button startIcon={<PlusCircleOutlined />} onClick={toggleDrawer(false)} sx={{ color: useIsActiveRoute("/custom-model") ? theme.palette.secondary.light : theme.palette.primary[100], fontWeight: 100, "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.primary[100] } }}><span style={{marginTop: '1px'}}>Create model</span></Button>
                    </Link> */}
                    <Link to="/profile" style={{ textDecoration: 'none' }}>
                        <Button startIcon={<UserOutlined />} onClick={toggleDrawer(false)} sx={{ color: useIsActiveRoute("/profile") ? theme.palette.secondary.light : theme.palette.primary[100], fontWeight: 100, "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.primary[100] } }}><span style={{ marginTop: '1px' }}>My Profile</span></Button>
                    </Link>
                    <Link to="/subscription" style={{ textDecoration: 'none' }}>
                        <Button startIcon={<DollarOutlined />} onClick={toggleDrawer(false)} sx={{ color: useIsActiveRoute("/billing") ? theme.palette.secondary.light : theme.palette.primary[100], fontWeight: 100, "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.primary[100] } }}><span style={{ marginTop: '1px' }}>Billing</span></Button>
                    </Link>
                    <Button onClick={navigateToDiscord} sx={{ paddingLeft: '2px', color: theme.palette.primary[100], fontWeight: 100, "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.primary[100] } }}><img style={{width: '20px', height: '20px', marginRight: '8px'}} src={DiscordGrey} /><span style={{ marginTop: '1px' }}>Join discord</span></Button>

                </Box>

            </Drawer>
        </>
    );
}