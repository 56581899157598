import { InfoCircleFilled, PlusCircleOutlined, UserOutlined, MenuOutlined, HomeOutlined } from "@ant-design/icons";
import { AppBar, Box, Button, ClickAwayListener, Drawer, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Stack, styled, Typography, useTheme } from "@mui/material"
import { Link, useNavigate } from "react-router-dom";
import Logo from 'assets/images/Logo.svg';
import UndresslyAiLogo from 'assets/images/undresslyAi.svg';
import Discord2 from 'assets/images/discord2.svg';
import { number } from "yup";
import { useState } from "react";
import HeaderDrewer from "./drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { HomeFilled, DollarOutlined } from "@ant-design/icons";
import React from "react";
import AccountMenu from "./account-menu";
import AddCreditsModal from "../subscription/modals/add-credits-modal";
import { config } from "@/contexts/config";

export type HeaderProps = {
    credits: number,
}

export const handleAddCredits = () => {
    const email = "hello@undressly.ai";
    const subject = "Buy 100 credits";
    const body = `Hello, I would like to purchase ${config.credits_count} credits for $${config.price}. Please send me the payment link.`;
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
}

export default function DashboardHeader({ credits }: HeaderProps) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [isAddCreditsModalOpen, setIsAddCreditsModalOpen] = useState<boolean>(false);

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
        setOpen(open);
    };

    const openAddCreditsModal = () => {
        setIsAddCreditsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsAddCreditsModalOpen(false);
    };

    const addCredits = () => {
        handleAddCredits();
        setIsAddCreditsModalOpen(false);
    }

    const navigateToDiscord = () => {
        window.open("https://discord.gg/SeRW3Y3ura", "_blank");
    }

    return (
        <>
            <AddCreditsModal isOpen={isAddCreditsModalOpen} onAddCredits={addCredits} handleClose={handleModalClose} /> 
            {/* Header Section */}
            <Box sx={{ width: '100%', height: '67px' }}>
                <AppBar sx={(theme) => ({
                    backgroundColor: theme.palette.primary.darker,
                    borderBottom: `1px solid #374151`,
                    backgroundImage: 'none',
                    boxShadow: 'none'
                })} position="static">
                    <Toolbar variant="regular" sx={{ width: '100%', paddingLeft: '10px', paddingRight: '10px', alignItems: 'center', }}>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: { xs: '0px', sm: '8px' } }}>
                                {/* Hamburger Menu - Visible on mobile */}
                                <IconButton
                                    sx={{ display: { xs: open ? 'none' : 'flex', sm: 'none' }, marginRight: '12px' }}
                                    onClick={toggleDrawer(true)}
                                >
                                    <MenuOutlined style={{ color: theme.palette.primary.contrastText, fontSize: '24px' }} />
                                </IconButton>

                                <Link to="/">
                                    <Box
                                        component="img"
                                        src={Logo}
                                        alt="Logo"
                                        sx={{
                                            height: {
                                                xs: '26px',
                                                sm: '16px'
                                            },
                                            marginLeft: '0px',
                                            marginRight: '7px',
                                            marginTop: { xs: '5px', sm: '0px' },
                                            width: 'auto',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </Link>
                                <Button
                                    onClick={navigateToDiscord}
                                    variant="text"
                                    sx={{ display: {xs: 'flex', sm: 'none'}, maxWidth: '30px', marginRight: '0px', marginTop: '5px', justifyContent: 'start' }}
                                >
                                    <img style={{width: '30px', height: '30px'}} src={Discord2} />
                                </Button>
                                <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                    <Link to="/">
                                        <img
                                            src={UndresslyAiLogo}
                                            alt="Undressly AI Logo"
                                            style={{ height: '16px', width: 'auto', cursor: 'pointer' }}
                                        />
                                    </Link>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '0px' }}>
                                <Button
                                    onClick={openAddCreditsModal}
                                    variant="text"
                                    startIcon={<InfoCircleFilled style={{ color: theme.palette.success.light, fontSize: '10px' }} />}
                                    sx={{ display: 'flex', marginRight: { xs: '1px', sm: '30px' }, textTransform: 'none', color: theme.palette.success.light, justifyContent: { xs: 'end', sm: 'center' } }}
                                >
                                    <span style={{ fontWeight: 'normal', marginTop: '1px' }}>{credits}</span> <Box sx={{ marginLeft: '5px', display: { xs: 'none', sm: 'flex' } }}><span style={{ fontWeight: 'normal' }}>credits</span></Box>
                                </Button>
                                <Button
                                    onClick={openAddCreditsModal}
                                    variant="text"
                                    startIcon={<PlusCircleOutlined style={{ color: theme.palette.secondary.light }} />}
                                    sx={{ display: 'flex', marginRight: { xs: '1px', sm: '10px' }, textTransform: 'none', color: theme.palette.secondary.light, justifyContent: { xs: 'end', sm: 'center' }, fontWeight: 'normal' }}
                                >
                                    Upgrade
                                </Button>
                                <Button
                                    onClick={navigateToDiscord}
                                    variant="text"
                                    sx={{ display: {xs: 'none', sm: 'flex'}, maxWidth: '20px', marginRight: '10px', marginTop: '5px', justifyContent: 'center' }}
                                >
                                    <img style={{width: '20px', height: '20px'}} src={Discord2} />
                                </Button>
                                <AccountMenu />
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>

                {/* HEADER DREWER SECTION */}
                <HeaderDrewer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />

            </Box>
        </>
    );
}