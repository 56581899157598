// material-ui
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';

// project import
import MainCard, { MainCardProps } from 'components/MainCard';
import { border, height, width } from '@mui/system';
import WomenImage from 'assets/images/undresslyAiWomen.svg';
import { Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import { config } from '@/contexts/config';

// ==============================|| ONE TIME PAYMENT - INFO PANEL ||============================== //

export default function OneTimePaymentInfoPanel() {
  const theme = useTheme();
  return (
    <MainCard
      sx={{
        backgroundColor: theme.palette.primary.darker,
        width: '100%',
        p: { xs: 2, sm: 3, md: 4 }
      }}
      content={false}
      border={false}
      boxShadow={false}
    >
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ color: theme.palette.primary[100] }}>
          {config.credits_count} credits purchase
        </Typography>
        <Typography variant="body1" sx={{ color: theme.palette.primary[100] }}>
          ${config.price}
        </Typography>
      </Box>
      <Divider sx={{ backgroundColor: theme.palette.primary[300] }} />
      <Box sx={{ mt: 3, mb: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4" sx={{ color: theme.palette.primary[100], fontWeight: 'normal' }}>
          Total
        </Typography>
        <Typography variant="h4" sx={{ color: theme.palette.primary[100], fontWeight: 'normal' }}>
          ${config.price}
        </Typography>
      </Box>
      <Divider sx={{ backgroundColor: theme.palette.primary[300] }} />
      <Stack spacing={2} sx={{ mt: 3, mb: 3 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <CheckCircleOutlined style={{ color: theme.palette.secondary[200], fontSize: '1.5rem' }} />
          <Typography variant="h6" sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' }, color: theme.palette.secondary[200] }}>
            100% Anonymous and Secure
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <CheckCircleOutlined style={{ color: theme.palette.secondary[200], fontSize: '1.5rem' }} />
          <Typography variant="h6" sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' }, color: theme.palette.secondary[200] }}>
            Undress AI Generated Models
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <CheckCircleOutlined style={{ color: theme.palette.secondary[200], fontSize: '1.5rem' }} />
          <Typography variant="h6" sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' }, color: theme.palette.secondary[200] }}>
            Create AI Deep Nudes
          </Typography>
        </Stack>
      </Stack>
    </MainCard>
  );
}
