// project import
import CheckoutWrapper from '@/sections/checkout/CheckoutWrapper';

// ==============================|| SUBSCRIPTION PAGE ||============================== //

export default function TestCheckoutPage() {
  return (
    <CheckoutWrapper />
  );
}
