// material-ui
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';

// project import
import MainCard, { MainCardProps } from 'components/MainCard';
import { border } from '@mui/system';

// ==============================|| SUBSCRIPTION - CARD WRAPPER ||============================== //

export default function SubscriptionCard({ children, ...other }: MainCardProps) {
  return (
    <MainCard
      sx={{ backgroundColor:'#1F2937', maxWidth: { xs: 600, lg: 750 }, marginTop: '20px', '& > *': { flexGrow: 1, flexBasis: '50%' } }}
      content={false}
      {...other}
      border={false}
      boxShadow={false}
    >
      <Box sx={{ paddingLeft: { xs: 2, sm: 3, md: 4, xl: 5 }, paddingRight: { xs: 2, sm: 3, md: 4, xl: 5 }}}>{children}</Box>
    </MainCard>
  );
}
