import BaseModal from "@/components/Modal";
import { Box, Button, DialogActions, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { config } from '@/contexts/config';

export type AddCreditsModalProps = {
    isOpen: boolean;
    onAddCredits: () => void;
    handleClose: () => void;
}

export default function AddCreditsModal({ isOpen, onAddCredits, handleClose }: AddCreditsModalProps) {
    const theme = useTheme();
    const navigate = useNavigate();
    

    return (
        <BaseModal
            addBorderOnContent={true}
            open={isOpen}
            onClose={handleClose}
            title="Add credits"
            actions={
                <DialogActions sx={{ display: 'flex', width: '100%', pl: '20px', pr: '20px', pb: '30px' }}>
                    <Button
                        onClick={() => {
                            navigate("/subscription");
                            // window.open('/checkout', '_blank');
                            // setTimeout(() => {
                            //     window.location.reload();
                            // }, 10000);
                        }}
                        variant="contained"
                        fullWidth
                        sx={{
                            color: theme.palette.common.white,
                            borderRadius: '8px',
                            padding: '12px',
                            fontWeight: 600
                        }}
                    >
                        Buy
                    </Button>
                </DialogActions>
            }
        >
            <Box sx={{
                backgroundColor: theme.palette.success.dark,
                color: theme.palette.common.black,
                padding: '1px',
                pl: '25px',
                pr: '25px',
                borderRadius: '4px',
                maxWidth: 'fit-content',
                mb: '10px'
            }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>Premium features</Typography>
            </Box>
            <Typography variant="h2" sx={{ mb: '12px', fontWeight: 600, fontSize: {xs: '24px', sm: '30px'} }}>
                Buy {config.credits_count} credits
            </Typography>
            <Typography variant="h1" sx={{ mb: '12px', fontWeight: 600, fontSize: {xs: '28px', sm: '40px'}, color: theme.palette.secondary.light }}>
                ${config.price}
            </Typography>
            <Typography
                variant="h5"
                sx={{ fontWeight: 'normal', fontSize: {xs: '15px', sm: '18px'}, color: theme.palette.primary.lighter, display: 'flex', alignItems: 'center', gap: '8px', mb: '18px' }}
            >
                <CheckOutlined style={{ color: theme.palette.secondary.main }} />
                Use All Undress modes
            </Typography>
            <Typography
                variant="h5"
                sx={{ fontWeight: 'normal', fontSize: {xs: '15px', sm: '18px'}, color: theme.palette.primary.lighter, display: 'flex', alignItems: 'center', gap: '8px', mb: '18px' }}
            >
                <CheckOutlined style={{ color: theme.palette.secondary.main }} />
                No watermark
            </Typography>
            <Typography
                variant="h5"
                sx={{ fontWeight: 'normal', fontSize: {xs: '15px', sm: '18px'}, color: theme.palette.primary.lighter, display: 'flex', alignItems: 'center', gap: '8px', mb: '18px' }}
            >
                <CheckOutlined style={{ color: theme.palette.secondary.main }} />
                Modify breasts/penis
            </Typography>
            <Typography
                variant="h5"
                sx={{ fontWeight: 'normal', fontSize: {xs: '15px', sm: '18px'}, color: theme.palette.primary.lighter, display: 'flex', alignItems: 'center', gap: '8px' }}
            >
                <CheckOutlined style={{ color: theme.palette.secondary.main }} />
                Download images
            </Typography>
        </BaseModal>

    );
}